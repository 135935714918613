import React from 'react';
import './Zsebrief.css';

export default function Template() {  
    return (

        <div className="footer-center">
            <div className="footer-center-left">COPYRIGHT 2024 | V040324</div>
            <div className="footer-center-right">INQUIRIES @ ZSEBRIEF . COM</div>
        </div>
        
   )

}

